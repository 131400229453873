import React, { useEffect } from "react";
import { SnackbarProvider } from "notistack";

// ** Declare Notification Provider
const NotificationProvider = ({ children }) => {
  const providerRef = React.useRef();

  useEffect(() => {
    if (providerRef.current) {
      const alert = (message, variant) => {
        providerRef.current.enqueueSnackbar(message, {
          variant: variant,
        });
      };
      window.alert = alert;
    }
  }, [providerRef.current]);
  return (
    <SnackbarProvider autoHideDuration={3000} ref={providerRef} maxSnack={3}>
      {children}
    </SnackbarProvider>
  );
};

export default NotificationProvider;
