import { Grid } from "@mui/material";
import Dashboard from "../components/Dashboard";



export default function App() {
  return (
    <div>
      <Grid container>
        <Dashboard />
      </Grid>
    </div>
  );
}
