import React from "react";
import { Grid, Paper, Button, Stack } from "@mui/material";
import { Link } from "react-router-dom";


import MBeaversMinted from "../assets/MBeaversMinted.png";
import BeaverVillains from "../assets/BeaverVillains.png";
import MutantBs from "../assets/MutantBs.png";
import Beavers4 from "../assets/Beavers4.png";

import SSquatch from "../assets/SSquatch.png";
import TKD from "../assets/TKD.png";
import monopolee from "../assets/monopolee.png";
import TKC from "../assets/TKC.png";



import UC from "../assets/UC.png";


const NFTDashboard = () => {
  return (
    <Grid 
      container
      direction="row"
      justifyContent="center"
      alignItems="stretch"
      spacing={2}
    >
      <Grid item xs={12} md={3}>
        <Paper
         class="transparent"
          elevation={3}
          sx={{ padding: 3, height: "100%", alignItem: "center" }}
        >
          <Stack 
            direction="column"
            justifyContent="space-evenly"
            alignItems="center"
            spacing={2}
            sx={{ height: "100%" }}
          >
            <img src={MBeaversMinted} style={{ width: "100%" }} />
            <Button
              component={Link}
              to="/marvelous-beavers"
              variant="contained"
              color="primary"
            >
              Go to DApp
            </Button>
          </Stack>
        </Paper>
      </Grid>
      <Grid item xs={12} md={3}>
        <Paper
         class="transparent"
          elevation={3}
          sx={{ padding: 3, height: "100%", alignItem: "center" }}
        >
          <Stack 
            direction="column"
            justifyContent="space-evenly"
            alignItems="center"
            spacing={2}
            sx={{ height: "100%" }}
          >
            <img src={BeaverVillains} style={{ width: "100%" }} />
            <Button
              component={Link}
              to="/beaver-villains"
              variant="contained"
              color="primary"
            >
              Go to DApp
            </Button>
          </Stack>
        </Paper>
      </Grid>
      <Grid item xs={12} md={3}>
        <Paper
         class="transparent"
          elevation={3}
          sx={{ padding: 3, height: "100%", alignItem: "center" }}
        >
          <Stack
            direction="column"
            justifyContent="space-evenly"
            alignItems="center"
            spacing={2}
            sx={{ height: "100%" }}
          >
            <img src={MutantBs} style={{ width: "100%" }} />
            <Button
              component={Link}
              to="/beaver-villains"
              variant="contained"
              color="primary"
            >
              Go to DApp
            </Button>
          </Stack>
        </Paper>
      </Grid>
      <Grid item xs={12} md={3}>
        <Paper
         class="transparent"
          elevation={3}
          sx={{ padding: 3, height: "100%", alignItem: "center" }}
        >
          <Stack
            direction="column"
            justifyContent="space-evenly"
            alignItems="center"
            spacing={2}
            sx={{ height: "100%" }}
          >
            <img src={Beavers4} style={{ width: "100%" }} />
            <Button
              component={Link}
              to="/beaver-villains"
              variant="contained"
              color="primary"
            >
              Go to DApp
            </Button>
          </Stack>
        </Paper>
      </Grid>


      
       {/* <Start of Second Row/>} /> */}


      <Grid item xs={12} md={3}>
        <Paper
         class="transparent"
          elevation={3}
          sx={{ padding: 3, height: "100%", alignItem: "center" }}
        >
          <Stack 
            direction="column"
            justifyContent="space-evenly"
            alignItems="center"
            spacing={2}
            sx={{ height: "100%" }}
          >
            <img src={SSquatch} style={{ width: "95%" }} />
            <Button
              component={Link}
              to="/marvelous-beavers"
              variant="contained"
              color="primary"
            >
              Go to DApp
            </Button>
          </Stack>
        </Paper>
      </Grid>
      <Grid item xs={12} md={3}>
        <Paper
         class="transparent"
          elevation={3}
          sx={{ padding: 3, height: "100%", alignItem: "center" }}
        >
          <Stack 
            direction="column"
            justifyContent="space-evenly"
            alignItems="center"
            spacing={2}
            sx={{ height: "100%" }}
          >
            <img src={TKD} style={{ width: "100%" }} />
            <Button
              component={Link}
              to="/beaver-villains"
              variant="contained"
              color="primary"
            >
              Go to DApp
            </Button>
          </Stack>
        </Paper>
      </Grid>
      <Grid item xs={12} md={3}>
        <Paper
         class="transparent"
          elevation={3}
          sx={{ padding: 3, height: "100%", alignItem: "center" }}
        >
          <Stack
            direction="column"
            justifyContent="space-evenly"
            alignItems="center"
            spacing={2}
            sx={{ height: "100%" }}
          >
            <img src={monopolee} style={{ width: "100%" }} />
            <Button
              component={Link}
              to="/marvelous-beavers"
              variant="contained"
              color="primary"
            >
              Go to DApp
            </Button>
          </Stack>
        </Paper>
      </Grid>
      <Grid item xs={12} md={3}>
        <Paper
         class="transparent"
          elevation={3}
          sx={{ padding: 3, height: "100%", alignItem: "center" }}
        >
          <Stack
            direction="column"
            justifyContent="space-evenly"
            alignItems="center"
            spacing={2}
            sx={{ height: "100%" }}
          >
            <img src={TKC} style={{ width: "100%" }} />
            <Button
              component={Link}
              to="/marvelous-beavers"
              variant="contained"
              color="primary"
            >
              Go to DApp
            </Button>
          </Stack>
        </Paper>
      </Grid>


{/* <Start of Third Row/>} /> */}


      <Grid item xs={12} md={3}>
        <Paper
         class="transparent"
          elevation={3}
          sx={{ padding: 3, height: "100%", alignItem: "center" }}
        >
          <Stack 
            direction="column"
            justifyContent="space-evenly"
            alignItems="center"
            spacing={2}
            sx={{ height: "100%" }}
          >
            <img src={UC} style={{ width: "50%" }} />
            <Button
              component={Link}
              to="/marvelous-beavers"
              variant="contained"
              color="primary"
            >
              Go to DApp
            </Button>
          </Stack>
        </Paper>
      </Grid>
      <Grid item xs={12} md={3}>
        <Paper
         class="transparent"
          elevation={3}
          sx={{ padding: 3, height: "100%", alignItem: "center" }}
        >
          <Stack 
            direction="column"
            justifyContent="space-evenly"
            alignItems="center"
            spacing={2}
            sx={{ height: "100%" }}
          >
            <img src={UC} style={{ width: "50%" }} />
            <Button
              component={Link}
              to="/marvelous-beavers"
              variant="contained"
              color="primary"
            >
              Go to DApp
            </Button>
          </Stack>
        </Paper>
      </Grid>
      <Grid item xs={12} md={3}>
        <Paper
         class="transparent"
          elevation={3}
          sx={{ padding: 3, height: "100%", alignItem: "center" }}
        >
          <Stack
            direction="column"
            justifyContent="space-evenly"
            alignItems="center"
            spacing={2}
            sx={{ height: "100%" }}
          >
            <img src={UC} style={{ width: "50%" }} />
            <Button
              component={Link}
              to="/marvelous-beavers"
              variant="contained"
              color="primary"
            >
              Go to DApp
            </Button>
          </Stack>
        </Paper>
      </Grid>
      <Grid item xs={12} md={3}>
        <Paper
         class="transparent"
          elevation={3}
          sx={{ padding: 3, height: "100%", alignItem: "center" }}
        >
          <Stack
            direction="column"
            justifyContent="space-evenly"
            alignItems="center"
            spacing={2}
            sx={{ height: "100%" }}
          >
            <img src={UC} style={{ width: "50%" }} />
            <Button
              component={Link}
              to="/marvelous-beavers"
              variant="contained"
              color="primary"
            >
              Go to DApp
            </Button>
          </Stack>
        </Paper>
      </Grid>















    </Grid>
  );
};

export default NFTDashboard;
