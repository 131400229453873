import { Paper, Box } from "@mui/material";
import { styled } from "@mui/material/styles";

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  textAlign: "center",
  color: theme.palette.text.secondary,
  lineHeight: "60px",
  backgroundColor: "rgba(1,1,1,0.5)",
  padding: "1em 2em"
}));

const ItemContainer = ({ children }) => (
  <Item elevation={3}>
    <Box>
    
      {children}
    </Box>
  </Item>
);

export default ItemContainer;
