import { Helmet } from "react-helmet";


const Charts = () => {

  return (
    <div className="bnb-chart-container">
      <Helmet>
        <script src="https://widget.nomics.com/embed.js"></script>
      </Helmet>

      <div
        className="nomics-ticker-widget"
        data-name="Binance Coin"
        data-base="BNB"
        data-quote="USD"
      ></div>
    </div>
  );
};

export default Charts;
