import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import "@rainbow-me/rainbowkit/dist/index.css";
import { midnightTheme, getDefaultWallets, RainbowKitProvider } from "@rainbow-me/rainbowkit";
import { chain, configureChains, createClient, WagmiConfig } from "wagmi";
import { alchemyProvider } from "wagmi/providers/alchemy";
import { publicProvider } from "wagmi/providers/public";
import Alchemy from "./web3/alchemy.js";
import { BrowserRouter } from 'react-router-dom'

import Main from './main'
import NotificationProvider from "./providers/notification.jsx";

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

const theme = createTheme({
  palette: {
    mode: "dark",
    black: {
      main: "#212121",
      contrastText: "#fff"
    },
    red: {
      main: "#d50000",
      contrastText: "#fff"
    },
    primary: {
      main: "#0e76fd",
      contrastText: '#fff'
    },
    secondary: {
        main: "#814cf4",
        contrastText: '#fff'

    }
  }
});

const { chains, provider } = configureChains(
  // [chain.mainnet, chain.polygon, chain.optimism, chain.arbitrum],
  [chain.polygon], 
  [alchemyProvider({ alchemyId: Alchemy.id }), publicProvider()]
);

const { connectors } = getDefaultWallets({
  appName: "NFT Steakhouse",
  chains
});

const wagmiClient = createClient({
  autoConnect: true,
  connectors,
  provider
});

root.render(
  <StrictMode>
    <NotificationProvider>
      <BrowserRouter>
        <WagmiConfig client={wagmiClient}>
          <RainbowKitProvider chains={chains} theme={midnightTheme()} >
            <ThemeProvider theme={theme}>
              <Main />
            </ThemeProvider>
          </RainbowKitProvider>
        </WagmiConfig>
      </BrowserRouter>
    </NotificationProvider>
  </StrictMode>
);
