import { Container, Grid, Box } from "@mui/material";
import { Outlet } from "react-router-dom";

import "./styles.css";
import BG from "./assets/background.jpg";

//import components
import Topbar from "./components/Topbar";

//import routes
import Dashboard from "./routes/Dashboard";
import NFTDashboard from "./routes/NFTDashboard";

export default function App() {
  return (
    <div
      className="App"
      style={{
        backgroundImage: `url(${BG})`,
        backgroundSize: "cover",
      }}
    >
      <Topbar />
      <div style={{ padding: "15px 15px" }}>
        <Outlet />
      </div>
    </div>
  );
}
