import React from "react";
import { Routes, Route } from "react-router-dom";

import App from "./App";
import Dashboard from "./routes/Dashboard";
import NFTDashboard from "./routes/NFTDashboard";
import BeaverVillains from "./routes/BeaverVillains";
import MarvelousBeavers from "./routes/MarvelousBeavers";
import Swap from "./routes/Swap";


const Main = () => {
  return (
    <Routes>
      <Route path="/" element={<App />}>
        <Route
          exact
          path="/"
          element={<Dashboard/>}
        />
        <Route path="nft-dashboard" element={<NFTDashboard/>} />
        <Route path="marvelous-beavers" element={<MarvelousBeavers/>} />
        <Route path="swap" element={<Swap/>} />

      </Route>
    </Routes>
  );
};

export default Main;
 {/*<Route path="beaver-villains" element={<BeaverVillains />} /> */}