import React, { useState, useEffect, useMemo } from "react";
import {
  Grid,
  Typography,
  Button,
  Select,
  MenuItem,
  Stack,
  Paper,
  InputLabel,
  TableRow,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableContainer,
  Card,
  CardActions,
  CardContent,
  CardMedia,
} from "@mui/material";
import { useAccount } from "wagmi";
import { ethers } from "ethers";

//Import Images
import Steakhouse from "../assets/MBeavers.png";
import Polygon from "../assets/polygon-matic-logo.png";
import BONES from "../assets/BONES.png";
import usdc from "../assets/usdc.png";

import ERC721ABI from "../web3/ERC721ContractABI.json";
import StakingABI from "../web3/StakingABI.json";
import ADDRESSES from "../web3/contractAddress";
import { useNftContract, useStakeContract } from "../hooks/useContract";

const NFTDashboard = () => {
  const { address, isConnected } = useAccount();
  const [nftQuantity, setNftQuantity] = useState(1);
  const [nftData, setNftData] = useState([]);
  const [isAllowed, setIsAllowed] = useState(false);
  const nftbalance = useMemo(() => {
    return nftData.filter((i) => i.staked == false).map((i) => i.token_id);
  }, [nftData]);

  const stakedbalance = useMemo(() => {
    return nftData.filter((i) => i.staked == true).map((i) => i.token_id);
  }, [nftData]);

  const nftContract = useNftContract();
  const stakingContract = useStakeContract();

  const [stakeInfo, setStakeInfo] = useState({
    _availableRewards: 0,
    _tokensStaked: 0,
  });

  // This is part that I changed from here
  const fetchNFTBalance = async () => {
    const provider = new ethers.providers.JsonRpcProvider(
      "https://polygon-rpc.com"
    );
    const beaver = new ethers.Contract(ADDRESSES.nft, ERC721ABI, provider);
    const staking = new ethers.Contract(
      ADDRESSES.staking,
      StakingABI,
      provider
    );

    //This checks how many NFTs user hold
    let ownList = await beaver.walletOfOwner(address);

    let items = [];

    //This loop gather all the info about NFTs held by user
    for (let i = 0; i < ownList?.length; i++) {
      const name = await beaver.name();
      const tokenAddress = "0x90A5CF7D19ED8a3e813C796541eF5125920d8a82";
      let tokenId = ownList[i];
      tokenId = tokenId.toNumber();
      const image =
        "https://nftstakehouse.mypinata.cloud/ipfs/QmNYDzSxWyTiajWoZsC1BCqQGQA2H8S2kbpKcgHr9iq2TL/" +
        tokenId.toString() +
        ".png";
      const metaName = "Marvelous Beavers #" + tokenId.toString();

      const item = {
        token_address: tokenAddress,
        image: image,
        projectName: name,
        name: metaName,
        token_id: tokenId,
        staked: false,
      };

      items.push(item);
    }

    //This function checks how many NFTs are held by staking contract
    let stakedBeavers = await beaver.balanceOf(ADDRESSES.staking);
    stakedBeavers = stakedBeavers.toNumber();
    let stakedBeaversId = [];

    //This loop gathers all the token IDs of staked NFTs
    for (let i = 0; i < stakedBeavers; i++) {
      const id = await beaver.tokenOfOwnerByIndex(ADDRESSES.staking, i);
      id = id.toNumber();
      stakedBeaversId.push(id);
    }

    //This loop checks all the staked NFTs and verifies if the user has any NFTs staked
    for (let i = 0; i < stakedBeavers; i++) {
      const id = stakedBeaversId[i];
      const owner = await staking.stakerAddress(id);
      if (owner == address) {
        const name = await beaver.name();
        const tokenAddress = "0x90A5CF7D19ED8a3e813C796541eF5125920d8a82";
        const image =
          "https://nftstakehouse.mypinata.cloud/ipfs/QmNYDzSxWyTiajWoZsC1BCqQGQA2H8S2kbpKcgHr9iq2TL/" +
          id.toString() +
          ".png";
        const metaName = "Marvelous Beavers #" + id.toString();

        const item = {
          token_address: tokenAddress,
          image: image,
          projectName: name,
          name: metaName,
          token_id: id,
          staked: true,
        };
        items.push(item);
      }
    }
    setNftData(items);
  };

  //Part that I changed to here

  //There are also few changes that I made in lines 403 412 416

  useEffect(() => {
    async function fetchData() {
      try {
        const allowed = await nftContract.isApprovedForAll(
          address,
          ADDRESSES.staking
        );
        setIsAllowed(allowed);
        fetchNFTBalance();
        const userStakeInfo = await stakingContract.userStakeInfo(address);
        setStakeInfo(userStakeInfo);
      } catch (error) {
        console.log(error);
      }
    }
    if (isConnected) {
      fetchData();
    }
  }, [isConnected]);

  const handleMsg = (error) => {
    const list = JSON.stringify(error.message).split("execution reverted: ");
    const msg =
      list.length > 1
        ? list[1].split('",')[0].slice(0, -1)
        : "Transaction failed";
    return msg;
  };

  const handleAuthorize = async () => {
    if (!address) return alert("Please connect your wallet first", "warning");

    try {
      await nftContract.setApprovalForAll(ADDRESSES.staking, true);
    } catch (error) {
      console.log(error);
    }
  };

  const handleClaim = async () => {
    if (!address) return alert("Please connect your wallet first", "warning");
    if (!Number(stakeInfo._availableRewards))
      return alert("You don't have rewards to claim", "warning");
    try {
      await stakingContract.claimRewards();
    } catch (error) {
      const msg = handleMsg(error);
      alert(msg, "error");
    }
  };

  const handleStake = async (token_id) => {
    if (!address) return alert("Please connect your wallet first", "warning");
    if (!isAllowed) {
      handleAuthorize();
    }

    try {
      await stakingContract.stake([token_id]);
    } catch (error) {
      const msg = handleMsg(error);
      alert(msg, "error");
    }
  };

  const handleStakeAll = async () => {
    if (!address) return alert("Please connect your wallet first", "warning");
    if (!nftbalance.length)
      return alert("You don't have NFTs to stake", "warning");
    if (!isAllowed) {
      handleAuthorize();
    }

    try {
      await stakingContract.stake(nftbalance);
    } catch (error) {
      const msg = handleMsg(error);
      alert(msg, "error");
    }
  };

  const handleUnStakeAll = async () => {
    if (!address) return alert("Please connect your wallet first", "warning");
    if (!stakedbalance.length)
      return alert("You don't have NFTs to withdraw", "warning");

    try {
      await stakingContract.withdraw(stakedbalance);
    } catch (error) {
      const msg = handleMsg(error);
      alert(msg, "error");
    }
  };

  const handleWithdraw = async (token_id) => {
    if (!address) return alert("Please connect your wallet first", "warning");

    try {
      await stakingContract.withdraw([token_id]);
    } catch (error) {
      const msg = handleMsg(error);
      alert(msg, "error");
    }
  };

  const handleMint = async () => {
    if (!address) return alert("Please connect your wallet first", "warning");

    try {
      await nftContract.mint(nftQuantity, {
        value: ethers.utils.parseEther(String(20 * nftQuantity)),
      });
    } catch (error) {
      if (error.data?.code === -32000 || error.code === "INSUFFICIENT_FUNDS") {
        return alert("Insufficient funds", "error");
      }
      const msg = handleMsg(error);
      alert(msg, "error");
    }
  };

  // console.log("NFT DATA:", nftData);

  return (
    <>
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12} md={6} lg={4}>
          <Paper elevation={3} sx={{ padding: 2 }}>
            <Typography variant="h4" gutterBottom>
              NFT Mint
            </Typography>
            <Typography variant="h5" gutterBottom></Typography>
            <Typography variant="body1" gutterBottom>
              Your wallet address
            </Typography>
            <Typography variant="caption" gutterBottom>
              {address ? address : "Please Connect your wallet"}
            </Typography>
            <InputLabel id="nft-quantity">Select NFT Quantity</InputLabel>
            <Select
              value={nftQuantity}
              label="Select NFT Quantity"
              labelId="nft-quantity"
              onChange={(e) => setNftQuantity(e.target.value)}
            >
              <MenuItem value={1}>1</MenuItem>
              <MenuItem value={2}>2</MenuItem>
              <MenuItem value={3}>3</MenuItem>
              <MenuItem value={4}>4</MenuItem>
              <MenuItem value={5}>5</MenuItem>
              <MenuItem value={6}>6</MenuItem>
              <MenuItem value={7}>7</MenuItem>
              <MenuItem value={8}>8</MenuItem>
              <MenuItem value={9}>9</MenuItem>
              <MenuItem value={10}>10</MenuItem>
            </Select>

            <Stack
              mt={3}
              direction="row"
              spacing={{ xs: 1, sm: 2, md: 4 }}
              justifyContent="center"
              alignItems="stretch"
            >
              <Button variant="contained" color="black" onClick={handleMint}>
                <img
                  src={BONES}
                  style={{ width: "100%", maxWidth: "100px" }}
                />
              </Button>
              <Button variant="contained" color="black" onClick={handleMint}>
                <img
                  src={usdc}
                  style={{ width: "100%", maxWidth: "100px" }}
                />
              </Button>
              <Button variant="contained" color="black" onClick={handleMint}>
                <img
                  src={Polygon}
                  style={{ width: "100%", maxWidth: "100px" }}
                />
              </Button>
            </Stack>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6} lg={8}>
          <Paper elevation={3} sx={{ padding: 2 }}>
            <Button
              variant="contained"
              color="primary"
              style={{ margin: "1em" }}
              disabled={isAllowed}
              onClick={handleAuthorize}
            >
              {isAllowed ? "Approved" : "Authorize your wallet"}
            </Button>
            <Grid container justifyContent="center">
              <Grid item xs={12} md={4}>
                <Paper sx={{ padding: 2, height: "90%" }}>
                  <Stack
                    direction="column"
                    justifyContent="center"
                    spacing={2}
                    height="100%"
                  >
                    
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleStakeAll}
                    >
                      Stake All
                    </Button>
                    <Typography>Your Staked NFTs</Typography>
                    <Typography>   </Typography>
                    <Typography>   </Typography>
                    <Typography>   </Typography>
                    <Typography>   </Typography>
                    <Typography>Total Staked NFTs</Typography>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleUnStakeAll}
                    >
                      Unstake All
                    </Button>
                  </Stack>
                </Paper>
              </Grid>
              <Grid
                item
                xs={12}
                md={4}
                style={{
                  display: "flex",
                  alignItems: "center",
                  padding: "20px",
                }}
              >
                <img src={Steakhouse} style={{ width: "100%" }} />
              </Grid>
              <Grid item xs={12} md={4}>
                <Paper sx={{ padding: 2, height: "90%" }}>
                  <Stack
                    direction="column"
                    justifyContent="center"
                    spacing={2}
                    height="100%"
                  >
                    
                    <Button variant="contained" color="primary">
                      Earned Bones
                    </Button>
                    <Typography> </Typography>
                    <Typography>Staking Rewards</Typography>
                    <Typography variant="caption">
                      {stakeInfo._availableRewards.toString()}
                    </Typography>
                    <Typography> </Typography>
                    <Typography>Claim Rewards</Typography>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleClaim}
                    >
                      Claim
                    </Button>
                  </Stack>
                </Paper>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      <Grid container spacing={3} marginTop={2}>
        <Grid item xs={12} md={6}>
          <Typography variant="h5">BONES Staking Farms</Typography>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="center">Farm Pools</TableCell>
                  <TableCell align="center">Harvest Daily Earnings</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell align="center">
                    Stake BONES to earn BONES
                  </TableCell>
                  <TableCell align="center">2 BONES</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center">VIP Knife Club BONES</TableCell>
                  <TableCell align="center">5 BONES</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="h5">NFT Staking Pool Active Rewards</Typography>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="center">Collection</TableCell>
                  <TableCell align="center">Rewards Per Day</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell align="center">HERO Collection</TableCell>
                  <TableCell align="center">5 BONES</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center">
                    VIP Knife Club Collection
                  </TableCell>
                  <TableCell align="center">10 BONES</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      <Paper elevation={3} sx={{ padding: 3, marginTop: 2 }}>
        <Typography variant="h5">Your NFT Portal</Typography>
        <Button
          disabled={!address}
          variant="contained"
          color="primary"
          onClick={fetchNFTBalance}
        >
          Refresh NFT Portal
        </Button>
        <Grid container mt={2} spacing={3}>
          {nftData &&
            nftData.map((r) => {
              if (r.token_address === ADDRESSES.nft)
                return (
                  <Grid item xs={12} md={4} lg={2} key={r.token_id}>
                    <Card>
                      <CardMedia
                        component="img"
                        width="100%"
                        image={r.image}
                        alt={r.projectName}
                      />
                      <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                          {r.name}
                        </Typography>
                      </CardContent>
                      <CardActions
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Button
                          size="small"
                          disabled={r.staked}
                          onClick={() => handleStake(r.token_id)}
                        >
                          Stake
                        </Button>
                        <Button
                          size="small"
                          disabled={!r.staked}
                          onClick={() => handleWithdraw(r.token_id)}
                        >
                          Unstake
                        </Button>
                      </CardActions>
                    </Card>
                  </Grid>
                );
            })}
        </Grid>
      </Paper>
    </>
  );
};

export default NFTDashboard;
