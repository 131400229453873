import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import { ConnectButton, darkTheme } from "@rainbow-me/rainbowkit";
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';


//import Icons
import AdbIcon from "@mui/icons-material/Adb";
import WalletIcon from "@mui/icons-material/AccountBalanceWallet";
import MenuIcon from "@mui/icons-material/Menu";

//import logo
import Logo from "../assets/logo-steakhouse.png";

const ResponsiveAppBar = () => {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleConnect = () => {};

  const shortenAddress = (address) => {
    return address.subStr(5) + "..." + address.subStr(-5);
  };

  return (
    <AppBar class="navbarglow"  color="black">
      <Container>
        <Toolbar disableGutters>
          <Box sx={{  flexGrow: 10, display: { xs: "none", md: "flex" } }}>
            <img src={Logo} height="100" />
          </Box>
          <Box sx={{ flexGrow: 5, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left"
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left"
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" }
              }}
            >
              <MenuItem component={RouterLink} to="/" >
                <Typography textAlign="center">Home</Typography>
              </MenuItem>
              <MenuItem component={RouterLink} to="/nft-dashboard" >
                <Typography textAlign="center">NFT Dashboard</Typography>
              </MenuItem >
              <MenuItem component={RouterLink} to="/swap" >
                <Typography textAlign="center">Swap</Typography>
              </MenuItem>
              <MenuItem onClick={handleCloseNavMenu}>
                <Typography textAlign="center">Token Staking</Typography>
              </MenuItem>
              <MenuItem onClick={handleCloseNavMenu}>
                <Typography textAlign="center">Max-E-Pad</Typography>
              </MenuItem>
              <MenuItem onClick={handleCloseNavMenu}>
                <Typography textAlign="center">Pawn Hub</Typography>
              </MenuItem>
              <MenuItem onClick={handleCloseNavMenu}>
                <Typography textAlign="center">VIP Knife Club</Typography>
              </MenuItem>
            </Menu>
          </Box>


          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            <Button
              component={RouterLink}
              to="/"
              sx={{ my: 1, mx: 1, fontSize: 16, color: "white", display: "block" }}
            >
              Home
            </Button>
            <Button
              component={RouterLink}
              to="/nft-dashboard"
              sx={{ my: 1, mx: 1, fontSize: 16, color: "white", display: "block", color: '#ff0000' }}
            >
              NFT Dashboard
            </Button>
            <Button
            component={RouterLink}
            to="/swap"
              sx={{ my: 1, mx: 1, fontSize: 16, color: "white", display: "block" }}
            >
              Swap
            </Button>
            <Button
              onClick={handleCloseNavMenu}
              sx={{ my: 1, mx: 1, fontSize: 16, color: "white", display: "block", color: '#ff0000' }}
            >
              Token Staking
            </Button>
            <Button
              onClick={handleCloseNavMenu}
              sx={{ my: 1, mx: 1, fontSize: 16, color: "white", display: "block"}}
            >
              Max-E-Pad
            </Button>
            <Button
              onClick={handleCloseNavMenu}
              sx={{ my: 1, mx: 1, fontSize: 16, color: "white", display: "block", color: '#ff0000' }}
            >
              Pawn Hub
            </Button>
            <Button
              onClick={handleCloseNavMenu}
              sx={{ my: 1, mx: 1, fontSize: 16, color: "white", display: "block"}}
            >
              VIP Knife Club
            </Button>
          </Box>

          {/* <Button
            variant="contained"
            color="red"
            onClick={handleConnect}
            startIcon={<WalletIcon />}
          >
            Connect Wallet
          </Button> */}
          <ConnectButton />
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default ResponsiveAppBar;
