import { React, useState } from "react";
import { ReactDOM } from "react-dom";
import { Typography, Grid, Box, Paper, Stack, Button } from "@mui/material";
import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";
import ItemContainer from "../components/ItemContainer";
import NFTDashContainer from "../components/NFTDashContainer";
import BannerContainer from "../components/BannerContainer";
import Bones from "../assets/BONES.png";
import Dashboard from "../components/Dashboard";










const Swap = () => {
  return (
    <Grid

      direction="row"
      justifyContent="center"
      alignItems="stretch"
      spacing={0}
      marginTop="20px"
    >
      
<Grid item>






<iframe src="https://flooz.trade/embed/swap?padding=20&roundedCorners=1&backgroundColor=transparent&primaryColor=%23814cf4&network=polygon"
  frameborder='0'
  height="600"
  width="400"
  allow="clipboard-read *; clipboard-write *; web-share *; accelerometer *; autoplay *; camera *; gyroscope *; payment *; geolocation *"
scrolling="no"
></iframe>


</Grid>

</Grid>
   
  
  )
  };
  










  export default Swap;