import { useEffect, useState } from 'react'
import { useSigner } from "wagmi";
import { ethers } from "ethers";
import ADDRESSES from "../web3/contractAddress";
import ERC721ABI from "../web3/ERC721ContractABI.json";
import StakingABI from "../web3/StakingABI.json";

const provider = new ethers.providers.JsonRpcProvider(
  "https://polygon-rpc.com"
);

const useContract = (abi, address) => {
  const { data: signer } = useSigner();
  const [contract, setContract] = useState(new ethers.Contract(address, abi, signer || provider))

  useEffect(() => {
    setContract(new ethers.Contract(address, abi, signer || provider))
  }, [abi, address, signer])

  return contract
}

/**
 * Helper hooks to get specific contracts (by ABI)
 */

export const useNftContract = () => {
  return useContract(ERC721ABI, ADDRESSES.nft)
}

export const useStakeContract = () => {
  return useContract(StakingABI, ADDRESSES.staking)
}

export default useContract;