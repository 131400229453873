import { useState } from "react";
import { Typography, Grid, Paper, Box} from "@mui/material";
import ItemContainer from "../components/ItemContainer";
import NFTDash from "../assets/NFTDash.png";
import Bones from "../assets/BONES.png";
import Thongs from "../assets/Thongs.png";
import { Link as RouterLink } from 'react-router-dom';
import TokenChart from "../components/TokenChart";
import MaticChart from "../components/MaticChart";
import getyourlove from "../assets/getyourlove.mov";
import NFTStakehouseTEST from "../assets/NFTStakehouseTEST.mov";
import StakehouseCartoon from "../assets/StakehouseCartoon.MOV";




const Dashboard = () => {
  const [balances, setBalances] = useState({
    accountBalance: "0",
    tokenBalance: "0",
  });



  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="stretch"
      spacing={2}
    >



      <Grid item xs={12} md={4}>
        <ItemContainer>
           <Box
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <img src={Bones} height={70} />
            <Typography variant="h5" mx={3} color="white">
              {"$0.00"}
            </Typography>
          </Box>
        </ItemContainer>
      </Grid>


      <Grid item xs={12} md={4}>
        <ItemContainer component={RouterLink} to="/nft-dashboard">
         <Box
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <img src={NFTDash} height={70} />
            </Box>
        </ItemContainer>
      </Grid>


      <Grid item xs={12} md={4}>
        <ItemContainer>
         <Box
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginTop: 0,
            }}
          >
            <img src={Bones} height={70} />
            <Typography mx={3} variant="h5" color="white">
              BONES
            </Typography>
            <Typography mx={3} variant="h5" color="white">{balances.tokenBalance}</Typography>
            </Box>
        </ItemContainer>
      </Grid>



      <Grid item xs={12} md={12}>
        <ItemContainer

            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginTop: 0,
            }}
          >
            <Typography mx={3} variant="h5" color="white">
              Your stake is ready.
            </Typography>
   
        </ItemContainer>
      </Grid>

      <Grid item xs={12} md={4}>
        <ItemContainer>

          <MaticChart className="matic-chart-container" />

        </ItemContainer>
      </Grid>


      <Grid item xs={12} md={4}>
        <ItemContainer>
          <a href="https://t.me/Thongslounge" target="_blank">

              <a href="https://t.me/Thongslounge" target="_blank">
                <img src={Thongs} height={200} />
              </a>

              <Typography variant="h5" mx={3} color="white" marginBottom={4}>
                Join Thong's Lounge for Project Updates
              </Typography>

          </a>
        </ItemContainer>
      </Grid>


      <Grid item xs={12} md={4}>
        <ItemContainer>
        

          <TokenChart className="bnb-chart-container" />
 
        </ItemContainer>
      </Grid>


      {/* <LEFT Paper/>} /> */}


      <Grid item xs={12} md={4}>
        <ItemContainer>
           <Box

            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              overflow: "hidden",
              marginTop: 0,
            }}
          >
       
            <video src={getyourlove} width="500" height="281" autoPlay="true" loop="true" muted="true" aspect-ratio= "16/9" />
          
            </Box>
        </ItemContainer>
      </Grid>


      {/* <MIDDLE Paper/>} /> */}


      <Grid item xs={12} md={4}>
        <ItemContainer>
           <Box

            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              overflow: "hidden",
              marginTop: 0,
            }}
          >

            <video src={NFTStakehouseTEST} width="500" height="281" autoPlay="true" loop="true" muted="true" aspect-ratio= "16/9"/>

            </Box>
        </ItemContainer>
      </Grid>


      {/* <RIGHT Paper/>} /> */}


      <Grid item xs={12} md={4}>
        <ItemContainer>
         <Box

            style={{
              display: "flex",
              flexDirection: "column",
              overflow: "hidden",
              alignItems: "center",
              marginTop: 0,
            }}
          >
   
            <video src={StakehouseCartoon} width="500" height="281" autoPlay="true" loop="true" muted="true" aspect-ratio= "16/9"/>

         </Box>
        </ItemContainer>
      </Grid>
    </Grid>
  );
};

export default Dashboard;




