import { Paper, Box } from "@mui/material";
import { styled } from "@mui/material/styles";

const Item  =  styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  textAlign: "center",
  color: theme.palette.text.secondary,
  lineHeight: "60px",
  backgroundColor: "rgba(1,1,1,0.5)",
  
}));

const BannerContainer = ({  children }) => (
  <Item elevation={3}>
    <Box 
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: 0,
      }}
    >
     {children}
      </Box>
  </Item>
);

export default BannerContainer;
